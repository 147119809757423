<template>
  <Card>
    <template #title>
      <h2>{{ adGroupName }}</h2>
    </template>
    <template #content>
      <div class="text-right mb-3">
        <ReportsCalendar
          v-model="dateRange"
        />
      </div>

      <SearchTermsTable
        :selected-profile="selectedProfile"
        :date-range="dateRange"
        :ad-group-id="adGroupId.toString()"
        :campaign-id="campaignId"
      />
    </template>
  </Card>
  <Toast />
</template>

<script>
import { mapGetters } from 'vuex';
import SearchTermsTable from './searchterms/SearchtermsTable.vue';
import { getDateRange } from '../../../../utils/dateUtils';
import ReportsCalendar from '../../../ReportsCalendar.vue';

export default {
  components: { SearchTermsTable, ReportsCalendar },
  inject: ['query'],
  props: {
    selectedProfile: {
      type: Object,
      required: true,
    },
    campaign: {
      type: Object,
      required: true,
    },
    adGroup: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dateRange: getDateRange(!!this.query?.mockAccountId),
    };
  },
  computed: {
    adGroupId() {
      return this.adGroup.adGroupId;
    },
    adGroupName() {
      return this.adGroup.name;
    },
    campaignId() {
      return this.campaign.campaignId;
    },
    ...mapGetters(['royalties']),

  },

};
</script>
